import {MRT_ColumnDef} from "mantine-react-table/dist";
import {TableColumnDto, TypeTask} from "./models/TaskLoadingDto";
import React from "react";


const maxValue = (items?: number[]) => {
    return Math.max(...items?.filter(x => x) ?? [0]) ?? 0;
}
const minValue = (items?: number[]) => {
    return Math.min(...items?.filter(x => x) ?? [0]) ?? 0;
}



export function GetColumns(columns: TableColumnDto[] | undefined, type: TypeTask): MRT_ColumnDef<TableColumnDto>[]{

    const columnsStat: MRT_ColumnDef<TableColumnDto>[] = [
        {
            accessorKey: 'ChannelFromFile',
            header: 'Ник канала',
            Cell: ({ cell }) => <a href={"https://t.me/" + cell.getValue()} target="_blank" rel="noopener noreferrer">{cell.getValue()}</a>,
        },
        {
            accessorKey: 'Name',
            header: 'Название канала',
        },
        {
            accessorKey: 'Subs',
            header: 'Подписчиков',
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            accessorFn: (row) => row.Subs ?? '',
            mantineFilterRangeSliderProps: {
                step: 1,
                min: minValue(columns?.map(o => parseInt(o.Subs))),
                max: maxValue(columns?.map(o => parseInt(o.Subs))),
            },
        },
        {
            accessorKey: 'AvgOnePublish',
            header: 'Просмотры сообщения',
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            accessorFn: (row) => row.AvgOnePublish ?? '',
            mantineFilterRangeSliderProps: {
                step: 1,
                min: minValue(columns?.map(o => parseInt(o?.AvgOnePublish))),
                max: maxValue(columns?.map(o => parseInt(o.AvgOnePublish))),
            },
        },
        {
            accessorKey: 'AvgOneAdvertisement',
            header: 'Просмотры рекламы',
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            accessorFn: (row) => row.AvgOneAdvertisement ?? '',
            mantineFilterRangeSliderProps: {
                step: 1,
                min: minValue(columns?.map(o => parseInt(o.AvgOneAdvertisement))),
                max: maxValue(columns?.map(o => parseInt(o.AvgOneAdvertisement))),
            },
        },
        {
            accessorKey: 'Comments',
            header: 'Комментарии',
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            accessorFn: (row) => row.Comments ?? '',
            mantineFilterRangeSliderProps: {
                step: 1,
                min: minValue(columns?.map(o => parseInt(o.Comments))),
                max: maxValue(columns?.map(o => parseInt(o.Comments))),
            },
        },
        {
            accessorKey: 'Reactions',
            header: 'Реакции',
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            accessorFn: (row) => row.Reactions ?? '',
            mantineFilterRangeSliderProps: {
                step: 1,
                min: minValue(columns?.map(o => parseInt(o.Reactions))),
                max: maxValue(columns?.map(o => parseInt(o.Reactions))),
            },
        },
        {
            accessorKey: 'HasAds',
            header: 'Размещается ли канал в Telegram Ads',
        },
        {
            accessorKey: 'Description',
            header: 'Описание',
        },
        {
            accessorKey: 'Error',
            header: 'Ошибка',
        },
    ];
    /*
        public string Channel { get; set; }
    public string Word { get; set; }
    public string TextAds { get; set; }
    public string DateFirst { get; set; }
    public string DateLast { get; set; }
    public string Link { get; set; }
    public string Error { get; set; }
    public bool Successfully { get; set; } = true;
     */
    const columnsAds: MRT_ColumnDef<TableColumnDto>[] = [
        // {
        //     accessorKey: 'Word',
        //     header: 'Слово',
        // },
        {
            accessorKey: 'Channel',
            header: 'Ник канала',
            Cell: ({ cell }) => <a href={"https://t.me/" + cell.getValue()} target="_blank" rel="noopener noreferrer">{cell.getValue()}</a>,
        },
        {
            accessorKey: 'TextAds',
            header: 'Текст сообщения',
        },
        {
            accessorKey: 'DateFirst',
            header: 'Дата начала сообщения',
        },
        {
            accessorKey: 'DateLast',
            header: 'Дата окончания сообщения',
        },
        {
            accessorKey: 'Link',
            header: 'Ссылка на сообщение',
            Cell: ({ cell }) => <a href={"https://t.me/" + cell.getValue()} target="_blank" rel="noopener noreferrer">{cell.getValue()}</a>,
        },
    ];
    const columnSimilar: MRT_ColumnDef<TableColumnDto>[] = [
        {
            accessorKey: 'Chat.MainUsername',
            header: 'Ник канала',
            Cell: ({ cell }) => <a href={"https://t.me/" + cell.getValue()} target="_blank" rel="noopener noreferrer">{cell.getValue()}</a>,
        },
        {
            accessorKey: 'Repetitions',
            header: 'Кол-во повторений',
            size: 50,
        },
    ];
    if (type === TypeTask.Similar)
        return columnSimilar;
    if (type === TypeTask.Stat)
        return columnsStat;
    if (type === TypeTask.AdsTelegram)
        return columnsAds;
    return [];
}